<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div class="w-100 pt-5">
          <img src="../../../assets/images/qii/qiiLogo2.png" class="w-50 mb-4" alt="" />
          <h1>
            {{ $t("I want to reuse my data") }}
          </h1>
          <p class="fw-medium text-gray">
            {{ $t("You can identify and reuse your data via our trusted partner Qii.") }}
          </p>

          <ul class="p-0 mb-4 fw-bold">
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />
              {{ $t("Currently only available for Dutch residents") }}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />
              {{ $t("May not be suitable for entrepreneurs") }}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />
              {{ $t("Computer and phone app required") }}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />
              {{ $t("Estimated completion time: 10 minutes") }}
            </li>
          </ul>

          <small class="text-danger" v-if="getErrors.profile">{{
            getErrors.profile
          }}</small>
          <div class="w-100 mt-4">
            <button
              class="btn btn-outline-primary btn-block"
              @click="submit('qii')"
              :disabled="getLoadings.profile"
            >
              {{ $t("Collect data using Qii") }}
              <b-spinner
                v-if="getLoadings.profile"
                small
                label="Spinning"
                variant="white"
              >
              </b-spinner>
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <!-- <img src="../../../assets/images/nordigen/nordigen_logo.png" alt=""> -->
          <img src="../../../assets/images/qii/step5.png" class="w-50" alt="" />
          <h1>
            {{ $t("I want to use my data once") }}
          </h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "Want to share your personal details directly? Create your Do you want to share your details directly, without the need for extra mobile apps?"
              )
            }}
          </p>

          <ul class="p-0 mb-4 fw-bold">
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />
              {{ $t("Suitable for all nationalities") }}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />
              {{ $t("Suitable for all income sources") }}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />
              {{ $t("No app required") }}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />
              {{ $t("Estimated completion time: 5 minutes") }}
            </li>
          </ul>
          <button
            class="btn btn-primary btn-block mb-3"
            :disabled="getLoadings.profile"
            @click="submit('expat')"
          >
            {{ $t("Collect data using this platform") }}
            <b-spinner v-if="getLoadings.profile" small label="Spinning" variant="white">
            </b-spinner>
          </button>
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import ExclamationCircle from "../../../components/svg/ExclamationCircle.vue";

export default {
  data() {
    return {
      error: "",
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    ExclamationCircle,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
    step() {
      return this.$route.params.step;
    },
  },
  async mounted() {
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      await this.getTenant(tenant_id);
    }
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    previous() {
      this.$router.go(-1);
    },
    async submit(flow) {
      if (flow == "qii") {
        this.$router.push({
          name: "QiiStep",
          params: { step: 1, tenant_id: this.tenant.id },
        });
      }

      if (flow == "expat") {
        this.$router.push({
          name: "ExpatStep1",
          params: { tenant_id: this.tenant.id },
        });
      }
    },
  },
  watch: {
    $route() {
      console.log("route watch");
      // this.step = this.$route.params.step;
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    max-width: 412px;

    @include xs {
      width: 93%;
    }
  }
}

li {
  margin-bottom: 10px;
}
</style>
